import React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Hero from '../components/Hero'
import { StaticImage } from 'gatsby-plugin-image'

const DownloadPageContent = () => (
  <div className="main">
    <section className="download__section download__section__bg-gray">
      <div className="download__inner download-list__inner">
        <h2 className="download-list__heading">個別資料</h2>
        <ul className="download-list__list-area">
          <li className="download-list__list-item">
            <a className="download-list__link" href="/downloads/input-form3/">
              <div className="download-list__thumbnail">
                <div className="download-list__thumbnail-ribbon">無料</div>
                <div className="download-list__img-wrapper">
                  <StaticImage className="download-list__img" src="../../static/image/downloads/download_replace.png" placeholder="blurred" alt="サムネイル" />
                </div>
              </div>
              <div className="download-list__text">
                <p className="download-list__name">システム開発・改修のリプレイスに関する実態調査</p>
                <p className="download-list__description">元の開発会社から他社にシステム開発・改修のリプレイス（乗り換え）を行ったことのある企業の経営者・役員、情報システム担当者107名に対して、リプレイスに関する課題を聞きました。
                </p>
              </div>
            </a>
          </li>
          <li className="download-list__list-item">
            <a className="download-list__link" href="/downloads/input-form2/">
              <div className="download-list__thumbnail">
                <div className="download-list__thumbnail-ribbon">無料</div>
                <div className="download-list__img-wrapper">
                  <StaticImage className="download-list__img" src="../../static/image/downloads/download_offshore.png" placeholder="blurred" alt="サムネイル" />
                </div>
              </div>
              <div className="download-list__text">
                <p className="download-list__name">ブリッジSE依存を超えた「新しいオフショア」の時代へ</p>
                <p className="download-list__description">オフショア開発を委託している企業で、システム開発に携わっている経営者・役員、経営企画・経営管理、情報システム担当者105名に聞きました。</p>
              </div>
            </a>
          </li>
          <li className="download-list__list-item">
            <a className="download-list__link" href="/downloads/input-form4/">
              <div className="download-list__thumbnail">
                <div className="download-list__thumbnail-ribbon">無料</div>
                <div className="download-list__img-wrapper">
                  <StaticImage className="download-list__img" src="../../static/image/downloads/download_vender.png" placeholder="blurred" alt="サムネイル" />
                </div>
              </div>
              <div className="download-list__text">
                <p className="download-list__name">システム開発予算、約4割が失敗 ─ 中小企業担当者が語るベンダー選定の壁</p>
                <p className="download-list__description">【中小企業の一般ユーザー向けシステム開発における適正価格の現実】<br/>
                担当者の約4割が予算設定で「失敗」した経験あり。その主な原因は「ベンダー選定の難しさ」。運用コストまで見据えた予算計画と慎重なベンダー選定が成功のカギ。</p>
              </div>
            </a>
          </li>
          <li className="download-list__list-item">
            <a className="download-list__link" href="/downloads/input-form5/">
              <div className="download-list__thumbnail">
                <div className="download-list__thumbnail-ribbon">無料</div>
                <div className="download-list__img-wrapper">
                  <StaticImage className="download-list__img" src="../../static/image/downloads/download_partner.png" placeholder="blurred" alt="サムネイル" />
                </div>
              </div>
              <div className="download-list__text">
                <p className="download-list__name">システム開発のパートナー選び ─ 成功する開発会社の選定ポイントとは？</p>
                <p className="download-list__description">本資料では、システム開発のパートナー選びに必要なポイントを分かりやすく解説し、初めての発注でもスムーズに進められるようにチェックリストを提供しています。ぜひダウンロードして、ご活用ください。</p>
              </div>
            </a>
          </li>
          <li className="download-list__list-item">
            <a className="download-list__link" href="/downloads/input-form6/">
              <div className="download-list__thumbnail">
                <div className="download-list__thumbnail-ribbon">無料</div>
                <div className="download-list__img-wrapper">
                  <StaticImage className="download-list__img" src="../../static/image/downloads/download_takeover.png" placeholder="blurred" alt="サムネイル" />
                </div>
              </div>
              <div className="download-list__text">
                <p className="download-list__name">システム会社の変更、ここだけは抑えよう ─ 失敗しないための重要ポイント解説</p>
                <p className="download-list__description">本資料では、 システム会社を変更する際に必ず押さえておくべきポイント を具体的に解説し、失敗しないための チェックリスト を提供します。
                システム会社の変更を検討している方は、ぜひダウンロードしてご活用ください。</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div className="download__inner download-list__inner">
        <h2 className="download-list__heading">資料5点セット</h2>
        <ul className="download-list__list-area">
          <li className="download-list__list-item">
            <a className="download-list__link" href="/downloads/input-form/">
              <div className="download-list__thumbnail">
                <div className="download-list__thumbnail-ribbon">無料</div>
                <div className="download-list__img-wrapper">
                  <StaticImage className="download-list__img" src="../../static/image/downloads/download_bundle_2.png" placeholder="blurred" alt="サムネイル" />
                </div>
              </div>
              <div className="download-list__text">
                <p className="download-list__name">企業担当者に聞いた！システム開発に関する調査結果（5点）おまとめダウンロード</p>
                <p className="download-list__description">株式会社もばらぶが企業担当者に対して実施した調査結果レポートをまとめてダウンロードできます。</p>
              </div>
            </a>
          </li>
          <li className="download-list__list-item">
          </li>
          <li className="download-list__list-item">
          </li>
        </ul>
      </div>
    </section>
  </div>
)

const DownloadPage = (props) => (
  <Layout
    title="ダウンロード | 株式会社もばらぶ"
    description="資料請求ページです。お役立ち資料を無料でダウンロードできます"
    pathName={props.location.pathname}
  >
    <Hero
      title="DOWNLOAD"
      titleJa="資料請求"
      body="資料請求ページです。お役立ち資料を無料でダウンロードできます"
    />
    <Header />
    <DownloadPageContent />
  </Layout>
)
export default DownloadPage